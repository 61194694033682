import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Grid, GridItem, Box } from '@chakra-ui/react';

import content from '../content';

import { Heading } from './Heading';

export const Contracts = () => {
    const { pastContracts } = content;

    return (
        <Grid
            gridTemplateColumns={{ base: '1fr', md: '1fr 3fr' }}
            justifyItems="space-between"
            gap={3}
        >
            <GridItem>
                <Heading mt={3} mb={1} fontSize={10} color="red.500">
                    Past
                </Heading>
                <Heading>
                    Contracts
                </Heading>
            </GridItem>
            <Grid
                gridTemplateColumns={{ base: 'repeat(3, 1fr)', md: 'repeat(4, 1fr)' }}
                alignItems="center"
                justifyItems="space-evenly"
                gap={3}
            >
                {pastContracts.map((item) => (
                    <GridItem key={uuidv4()}>
                        <Box maxW={{ base: '75px', md: '100px' }}>
                            {item}
                        </Box>
                    </GridItem>
                ))}
            </Grid>
        </Grid>
    )
}