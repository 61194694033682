import React from 'react';

import { Outlet } from 'react-router-dom';

import { Grid, GridItem } from '@chakra-ui/react';

import { PrimaryNav } from '../PrimaryNav';
import { Footer } from '../Footer';

export const RootLayout = () => {
    return (
        <Grid
            width="100%"
            maxWidth={1024}
            margin="0 auto"
            padding={{ base: '28px', md: '48px 28px' }}
            color="black"
        >
            <GridItem>
                <PrimaryNav />
            </GridItem>
            <GridItem >
                <Outlet />
            </GridItem>
            <GridItem>
                <Footer />
            </GridItem>
        </Grid >
    )
}