import React from 'react';

import { Grid, Flex, Heading as Chakraheading } from '@chakra-ui/react';

import content from '../../content';

import { Heading } from '../Heading';

const TalentItem = ({ title, icon }) => {
    const Icon = icon;
    return (
        <Flex
            flexDir="column"
            alignItems="center"
            gap={3}
            fontSize={30}
            color="black"
        >
            <Icon weight="duotone" />
            <Chakraheading
                as="h4"
                fontSize={{ base: 12, md: 14 }}
            >
                {title}
            </Chakraheading>
        </Flex>
    )
}

export const Talent = () => {
    const { skillDefinition } = content;

    return (
        <Grid
            gridTemplateColumns={{ base: '1fr', md: '1fr 2fr' }}
            alignItems="center"
            justifyItems="space-between"
            gap={{ base: 8, md: 3 }}
        >
            <Heading
                as="h3"
                textAlign={{ base: 'center', md: 'left' }}
                display={{ base: 'none', md: 'block' }}
            >
                Talent
            </Heading>
            <Grid
                gridTemplateColumns="1fr 1fr 1fr 1fr"
                alignItems="center"
            >
                {skillDefinition.map(({ title, icon }) => (
                    <TalentItem
                        key={title}
                        title={title}
                        icon={icon}
                    />
                ))}
            </Grid>
        </Grid>
    )
}