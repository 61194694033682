import React, { useState } from 'react';

// import { List, X } from '@phosphor-icons/react';
import { Flex, Grid, GridItem, Box, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import mixpanel from 'mixpanel-browser';

import content from '../../content';

import { sections } from '../Section/sections';
import { Heading } from '../Heading';

// const AnimatedX = () => (
//     <AnimatePresence>
//         <motion.span
//             initial={{ opacity: 0 }}
//             animate={{ opacity: 1 }}
//             exit={{ opacity: 0 }}
//         >
//             <X />
//         </motion.span>
//     </AnimatePresence>
// )

// const AnimatedList = () => (
//     <AnimatePresence>
//         <motion.span
//             initial={{ opacity: 0 }}
//             animate={{ opacity: 1 }}
//             exit={{ opacity: 0 }}
//         >
//             <List />
//         </motion.span>
//     </AnimatePresence>
// )

const SocialLink = ({
    link,
    label,
    icon
}) => {
    const Icon = icon;

    return (
        <Link
            aria-label={label}
            href={link}
            target="_blank"
            onClick={() => {
                mixpanel.track(`${label} Link Click`, {
                    url: link,
                })
            }}
            _hover={{
                color: 'red.500'
            }}
        >
            <Icon weight="duotone" />
        </Link>
    )
}

// const MenuToggle = ({ isOpen, toggle }) => {
//     const { ref } = useRef();

//     return (
//         <Link
//             onClick={toggle}
//             ref={ref}
//             fontSize={24}
//             color="black"
//             _hover={{
//                 cursor: 'pointer',
//                 color: 'red.500'
//             }}
//             _active={{
//                 color: 'red.500'
//             }}
//             aria-selected={isOpen}
//         >
//             {isOpen ? <AnimatedX /> : <AnimatedList />}
//         </Link>
//     )
// }

export const PrimaryNav = () => {
    const [isOpen] = useState(false);
    const { social } = content;

    return (
        <Grid gridTemplateColumns="1fr 1fr" gap={8}>
            <GridItem>
                <Link
                    as={RouterLink}
                    to="/"
                    _hover={{
                        color: 'red.500'
                    }}
                >
                    <Heading fontSize={16}>
                        SAM B.
                    </Heading>
                </Link>
            </GridItem>
            <AnimatePresence>
                {isOpen && (
                    <GridItem
                        display="flex"
                        flexDirection={['column', 'column', 'row']}
                        alignItems="center"
                        justifyContent="flex-end"
                        gap={12}
                        zIndex={1}
                    >
                        {sections.map((section, idx) => (
                            <Box key={section.key}>
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1, transition: { delay: idx / 20 } }}
                                    exit={{ opacity: 0, transition: { delay: idx / 20 } }}
                                >
                                    <Link
                                        key={section.key}
                                        href={`#${section.key}`}
                                        aria-label={section.title}
                                        _hover={{
                                            color: 'red.500'
                                        }}
                                    >
                                        {section.title}
                                    </Link>
                                </motion.div>
                            </Box>
                        ))}
                    </GridItem>
                )}
            </AnimatePresence>
            <GridItem gridColumn="none">
                <Flex alignItems="center" gap={8}>
                    <Flex alignItems="center" gap={4} fontSize={20}>
                        {social.map((item) => (
                            <SocialLink key={item.label} {...item} />
                        ))}
                    </Flex>
                    {/* <Box>
                        <MenuToggle
                            isOpen={isOpen}
                            toggle={() => toggle(!isOpen)}
                        />
                    </Box> */}
                </Flex>
            </GridItem>
        </Grid >
    )
}