import { About } from './About';
import { Experience } from './Experience';
// import { Projects } from './Projects';

export const sections = [
    {
        key: 'talent',
        title: 'Talent',
        component: About,
    },
    {
        key: 'work',
        title: 'Work',
        component: Experience,
    },
    // {
    //     key: 'lab',
    //     title: 'Lab',
    //     component: Projects,
    // }
];