import React from 'react';

import { Heading as ChakraHeading } from '@chakra-ui/react'

export const Heading = ({ children, ...props }) => (
    <ChakraHeading
        color="black"
        textTransform="uppercase"
        letterSpacing="0.1em"
        fontSize={20}
        margin={0}
        {...props}
    >
        {children}
    </ChakraHeading>
);