import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Grid, GridItem } from '@chakra-ui/react';

import { sections } from '../components/Section/sections';

import { Hero } from '../components/Hero';
import { Section } from '../components/Section';

export const Home = () => {
    return (
        <Grid>
            <GridItem>
                <Hero />
            </GridItem>
            <GridItem>
                <Grid gap={{ base: 16, md: 28 }}>
                    {sections.map((section) => {
                        const SectionComponent = section.component;
                        return (
                            <GridItem key={uuidv4()}>
                                <Section meta={section}>
                                    <SectionComponent key={section.key} />
                                </Section>
                            </GridItem>
                        )
                    })}
                </Grid>
            </GridItem>
        </Grid>
    )
}