import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import mixpanel from 'mixpanel-browser';

import { Grid, GridItem, Text, Tag, Flex } from '@chakra-ui/react';

import content from '../content';

import { Heading } from './Heading';

const SmallContractItem = ({
    website,
    title,
    duration,
    tags
}) => (
    <Flex alignItems="center" gap={4}>
        <Text
            fontSize="xs"
            fontWeight={800}
            textTransform="uppercase"
            letterSpacing="0.1em"
        >
            {duration}
        </Text>
        {website ? (
            <a
                href={website}
                target="_blank"
                rel="noreferrer"
                aria-label={title}
                onClick={() => {
                    mixpanel.track(`${title} Link Click`, {
                        url: website,
                    })
                }}
            >
                {title}
            </a>
        ) : (
            title
        )}
        {tags && tags.map((tag) => <Tag key={uuidv4()} size="sm" colorScheme="blackAlpha">{tag}</Tag>)}
    </Flex>
);

export const SmallContracts = () => {
    const { activeContracts } = content;

    return (
        <Grid
            gridTemplateColumns={{ base: '1fr', md: '1fr 3fr' }}
            justifyItems="space-between"
            gap={3}
        >
            <GridItem>
                <Heading fontSize={10} color="red.500" mb={1}>
                    Active
                </Heading>
                <Heading>
                    Clients
                </Heading>
            </GridItem>
            <Grid
                gridTemplateColumns="1fr"
                alignItems="center"
                justifyItems="space-evenly"
                gap={3}
            >
                {activeContracts.map((item) => (
                    <SmallContractItem key={uuidv4()} {...item} />
                ))}
            </Grid>
        </Grid>
    )
}