import React, { useState, useEffect } from 'react';

import { motion, AnimatePresence } from 'framer-motion';

import { Box, Heading } from '@chakra-ui/react';

const TextLoop = ({ texts }) => {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            let next = index + 1;
            setIndex(next % texts.length);
        }, 3 * 1000);
    }, [index, setIndex, texts]);

    return (
        <AnimatePresence>
            <motion.span
                layout
                key={index}
                initial={{
                    y: 50,
                    opacity: 0,
                }}
                animate={{
                    y: 0,
                    opacity: 1,
                }}
                exit={{
                    y: -50,
                    opacity: 0,
                    display: 'none'
                }}
                transition={{
                    y: { type: "spring", stiffness: 1000, damping: 200 },
                    opacity: { duration: 0.5 }
                }}
            >
                {texts[index]}
            </motion.span>
        </AnimatePresence>
    );
};

export const Hero = () => (
    <motion.section
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{
            duration: 0.8,
        }}
    >
        <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            height={{ base: '300px', md: '600px' }}
        >
            <Box
                display="flex"
                flexDirection="column"
                gap={2}
            >
                <Heading
                    as="h1"
                    fontSize={["xl", "1xl", "2xl"]}
                    textTransform="uppercase"
                    letterSpacing="0.1em"
                    fontWeight={800}
                >
                    <span>Design <span className="red">+</span> Development</span> {' '}
                    <TextLoop texts={['Engineer', 'Mentor', 'Manager']} />
                </Heading>
                <Heading
                    as="h2"
                    fontSize={["xl", "1xl", "2xl"]}
                    fontWeight={300}
                >
                    Finding balance between <span className="red">art</span> and <span className="red">science</span>.
                </Heading>
            </Box>
        </Box>
    </motion.section>
);