import {
  GithubLogo,
  LinkedinLogo,
  Mailbox,
  Flask,
  Crop,
  MoonStars,
  Planet,
  FlyingSaucer,
  Acorn,
  Campfire,
  Chats,
  Confetti,
  Farm
} from '@phosphor-icons/react';

import BlueDiamondLogo from './svgs/BlueDiamond';
import GoogleLogo from './svgs/Google';
import AmazonLogo from './svgs/Amazon';
import HermesLogo from './svgs/Hermes';
import TimeLogo from './svgs/Time';
import OneSight from './svgs/OneSight';
import Prudential from './svgs/Prudential';
import Univision from './svgs/Univision';

const content = {
  site: {
    title: 'Samantha Black',
    meta: {
      url: 'https://sam.black',
      siteName: 'Samantha Black | UI/UX Engineer',
      description: 'UI/UX Engineer with over 15 years of experience in the tech industry. She specializes in crafting enterprise-ready applications and has worked with clients such as Google, Amazon, Hermes, and Time.',
      tags: [
        'UI/UX Engineer',
        'Full Stack Developer',
        'Cloud Application Development',
        'Design Thinking',
        'Visual Design',
        'Interactions',
        'UI Design',
        'UX Design',
        'Web Design',
        'Web Development',
        'App Design',
        'App Development',
        'Version Control',
        'Design System',
        'Debugging & Testing',
        'Navigation Architecture',
        'Information Architecture',
        'Responsive Design & Development',
        'Prototyping'
      ],
    }
  },
  self: {
    name: 'Samantha B.',
    location: 'Cincinnati, Ohio',
    email: 'sam@sam.black',
    shortBio: `I'm Samantha, User Experience Designer & Front-End Developer.`,
  },
  skillDefinition: [
    {
      title: 'Research',
      icon: Flask,
    },
    {
      title: 'UI-UX Design',
      icon: Crop,
    },
    {
      title: 'Development',
      icon: MoonStars,
    },
    {
      title: 'Leadership',
      icon: Planet,
    },
  ],
  workflow: {
    description: 'I tackle a broad variety of displines in the agile process of creating meaningful products.',
    steps: ['research', 'product design', 'UX design & prototyping', 'UI design', 'development', 'delivery']
  },
  skillsPerCategory: [
    {
      category: 'Development',
      keyItems: [
        'Agile Development',
        'Accessibility',
        'Frameworks',
        'Components',
        'Systems',
        'Environment',
      ],
      tools: [
        'React.js',
        'TypeScript',
        'GraphQL',
        'Vercel',
        'CI/CD',
        'Node.js',
        'Webpack',
        'Gatsby.js',
        'Next.js',
        'Jest',
        'Cypress',
        'Storybook',
        'Chakra UI',
        'Styled Components',
        'Github',
      ]
    },
    {
      category: 'UX',
      keyItems: [
        'Personas',
        'User Research',
        'User Journeys',
        'Information Architecture',
        'Wireframing',
        'Usability Testing + Evaluation',
        'Accessibility'
      ],
      tools: [
        'Figma',
        'Sketch',
        'Adobe Creative Suite',
        'Pen & Paper',
      ]
    },
    {
      category: 'Managment',
      keyItems: [
        'Team leadership',
        'Project Management',
        'Mentoring',
        'Agile Methodologies',
        'Slack',
        'Zoom',
        'Remote work'
      ],
    },
    {
      category: 'Mentorship',
      keyItems: [
        'Code reviews',
        'Pair programming',
        'Career development',
        'Mentoring',
        'Training',
      ],
    }
  ],
  social: [
    {
      link: `mailto:sam@sam.black?subject=Hey! Let's chat about working together :)`,
      label: 'Email',
      icon: Mailbox,
    },
    {
      link: 'https://github.com/samblackk',
      label: 'Github',
      icon: GithubLogo,
    },
    {
      link: 'https://linkedin.com/in/samantha-black-engineer',
      label: 'LinkedIn',
      icon: LinkedinLogo,
    },
    // {
    //   link: 'https://samblackk.medium.com',
    //   label: 'Medium',
    //   icon: MediumLogo,
    // }
  ],
  education: [
    {
      school: 'Full Sail University',
      degree: 'Bachelor of Science - BS, Web Development'
    }
  ],
  pastContracts: [
    <BlueDiamondLogo />,
    <GoogleLogo />,
    <HermesLogo />,
    <OneSight />,
    <AmazonLogo />,
    <Prudential />,
    <TimeLogo />,
    <Univision />
  ],
  activeContracts: [
    {
      title: 'Candlespice Co.',
      website: 'https://candlespice.com/',
      duration: 'est. 2018',
      tags: ['retail']
    },
    {
      title: 'Orion Electric + Contracting',
      duration: 'est. 2024',
    }
  ],
  labProjects: [
    {
      icon: FlyingSaucer,
      title: 'Example Name',
      url: 'https://example.com'
    },
    {
      icon: Acorn,
      title: 'Example Name',
      url: 'https://example.com'
    },
    {
      icon: Campfire,
      title: 'Example Name',
      url: 'https://example.com'
    },
    {
      icon: Chats,
      title: 'Example Name',
      url: 'https://example.com'
    },
    {
      icon: Confetti,
      title: 'Example Name',
      url: 'https://example.com'
    },
    {
      icon: Farm,
      title: 'Example Name',
      url: 'https://example.com'
    },
  ],
  history: [
    {
      startedAt: 'January 2024',
      endedAt: 'Present',
      company: 'Acryl Data',
      website: 'https://www.acryldata.io/',
      position: 'Software Engineer, Product + OSS',
      points: [
        'Prioritized high-quality code, continuous integration, and frequent revisions to ensure exceptional user experience.',
        'Contributed to fostering a collaborative and inclusive team culture, supporting innovation and continuous improvement.'
      ]
    },
    {
      startedAt: 'May 2019',
      endedAt: 'December 2023',
      company: 'Astronomer, Inc.',
      website: 'https://www.astronomer.io/',
      position: 'Principal Software Engineer, DevEx + OSS',
      otherPositions: ['Software Engineering Manager, Product', 'Senior Software Engineer, Product'],
      points: [
        'Early team member driving foundational initiatives Pre-Series A through Series C.',
        'Spearheaded key technological advancements, including rebuilding the Cloud UI and enhancing the GraphQL API.',
        `Contributed to shaping the company's core values, fostering innovation, collaboration, and inclusivity.`,
        'Promoted to Engineering Manager for contributions to technical architecture and leadership.',
        'Managed a team of 8, with 6 direct reports, demonstrating effective management and mentorship. Mentored 2 interns.',
        'Transitioned to Principal Software Engineer post-restructure, leading Open Source initiatives and Developer Experience projects.',
      ],
      projects: [
        {
          name: 'Astro',
          type: 'Cloud App',
          link: 'https://www.astronomer.io/product/',
        },
        {
          name: 'Astro Registry',
          type: 'Web App',
          link: 'https://registry.astronomer.io/'
        },
        {
          name: 'Founder of AstroWomen',
          type: 'ERG',
          link: '#'
        }
      ]
    },
    {
      startedAt: 'May 2016',
      endedAt: 'May 2019',
      company: 'Planetary, Co.',
      website: 'https://planetary.co/',
      position: 'Senior Front-End Engineer',
      points: [
        'Specialized in building digital products using Node stacks, excelling in technical expertise and leadership.',
        'Prioritized top-notch code quality, continuous integration, and frequent revisions.',
        'Delivered products that exceeded expectations and provided an exceptional user experience.',
      ],
      projects: [
        {
          name: 'Spacetime',
          type: 'Web App',
          link: 'https://spacetime.am/',
        },
        {
          name: 'Blue Diamond Almonds',
          type: 'Website',
          link: 'https://www.bluediamond.com/'
        },
        {
          name: 'Economic Security Project',
          type: 'Website',
          link: 'https://www.economicsecurityproject.org/'
        }
      ]
    },
    {
      startedAt: 'January 2009',
      endedAt: 'May 2016',
      company: 'RNDPXL',
      position: 'Lead Front-End Engineer',
      otherPositions: ['Junior Web Developer'],
      points: [
        'Began career as a Junior Web Developer, transforming designs into websites using HTML, CSS, and JavaScript.',
        'Developed and deployed PHP and WordPress platforms, including themes and plugins.',
        'Developed and deployed Shopify platforms, including themes and plugins.',
        'Developed strong communication skills, client relations, and leadership abilities.'
      ],
      projects: [
        {
          name: 'Cincinnati Christian University',
          type: 'Website',
          link: 'https://ccuniversity.edu/',
        },
        {
          name: 'OneSight',
          type: 'Website',
          link: 'https://onesight.org/',
        }
      ]
    },
  ],
  reviews: [
    {
      content: `As a designer, I always hope that the developers that will build
      out my work will require little hand holding and redlining. Sam is exactly that;
      taking the initiative and using her expertise to accurately bring it all to life.
      I hope to keep working along side her for many years to come and can only
      hope that more developers were as wonderful to work with.`,
      cite: 'Valdis Barrett',
      title: 'Sr. UX/UI Consultant | Planetary'
    },
    {
      content: `I've had the pleasure of working with Samantha for over two years at Planetary.
      She is, far and away one of the best employees I've had the fortune to work with.
      Determined, committed, and very self-driven, Samantha has worked hard to not only worked
      to achieve her day-to-day engineering goals but has regularly gone above-and-beyond.`,
      cite: 'Joshua Gross',
      title: 'Partner | Planetary'
    }
  ],
  siteFooter: `Loosely designed in Figma and coded in Visual Studio Code by yours truly. Built with React.js and Chakra UI, deployed with Netlify. Poppins & Source Code Pro typeface.`,
};

export default content;
