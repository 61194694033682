import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import mixpanel from 'mixpanel-browser';

import { Grid, GridItem, Heading as ChakraHeading, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';

import content from '../../content';

import { Heading } from '../Heading';
import { Contracts } from '../Contracts';
import { SmallContracts } from '../SmallContracts';

const ExperienceItem = ({
    startedAt,
    endedAt,
    position,
    otherPositions,
    company,
    website,
    description,
    points
}) => (
    <motion.section
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{
            duration: 0.8,
        }}
    >
        <Grid gridTemplateColumns={{ base: '1fr', md: '1fr 3fr' }} gap={4}>
            <GridItem>
                <Text
                    fontSize="xs"
                    fontWeight={800}
                    textTransform="uppercase"
                    letterSpacing="0.1em"
                >
                    {startedAt} ― {endedAt}
                </Text>
            </GridItem>
            <GridItem>
                <ChakraHeading
                    as="h5"
                    fontSize={16}
                    mb={2}
                >
                    {position}
                    {' '}·{' '}
                    <a
                        href={website}
                        target="_blank"
                        rel="noreferrer"
                        aria-label={company}
                        onClick={() => {
                            mixpanel.track(`${company} Link Click`, {
                                url: website,
                            })
                        }}
                    >
                        {company}
                    </a>
                </ChakraHeading>
                {otherPositions && otherPositions.map((item) => (
                    <ChakraHeading
                        key={uuidv4()}
                        as="h6"
                        fontSize={14}
                        mb={2}
                        fontWeight={300}
                        opacity={0.75}
                    >
                        — {item}
                    </ChakraHeading>
                ))}
                {description && <Text>{description}</Text>}
                {points && (
                    <ul>
                        {points.map((point) => (
                            <li key={uuidv4()}>{point}</li>
                        ))}
                    </ul>
                )}
            </GridItem>
        </Grid>
    </motion.section>
)

export const Experience = () => {
    const { history } = content;

    return (
        <Grid gap={{ base: 16, md: 28 }}>
            <Grid gap={{ base: 8, md: 16 }}>
                <GridItem>
                    <Heading mt={3} mb={1} fontSize={10} color="red.500">
                        Work
                    </Heading>
                    <Heading>
                        History
                    </Heading>
                </GridItem>
                {history.map((item) => (
                    <ExperienceItem key={uuidv4()} {...item} />
                ))}
            </Grid>
            <SmallContracts />
            <Contracts />
        </Grid>
    )
}