import React from 'react';
import mixpanel from 'mixpanel-browser';

import { ChakraProvider } from '@chakra-ui/react';

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';

import { Helmet, HelmetProvider } from 'react-helmet-async';

import { Home } from './pages/home';
import { Error404 } from './pages/404';

import { RootLayout } from './components/layouts/root';

import theme from './theme';
import content from './content';

import OGImage from './content/SamanthaBlackOG.png';

const helmetContext = {};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<RootLayout />}>
      <Route path="/" element={<Home />} />
      <Route path="*" element={<Error404 />} />
    </Route>
  )
);

function App() {
  mixpanel.init('20eaff0378a4f31fef36bc443695f45d', {
    debug: false,
    track_pageview: true,
    persistence: 'localStorage'
  });

  return (
    <HelmetProvider context={helmetContext}>
      <ChakraProvider theme={theme}>
        <Helmet>
          <title>{content.site.title}</title>
          <link rel="canonical" href={window.location.href} />
          <meta name="description" content={content.site.meta.description} />
          <meta name="keywords" content={content.site.meta.tags.join(', ')} />
          <meta name="image" content={OGImage} />
          <meta property="og:title" content={content.site.title} />
          <meta property="og:description" content={content.site.meta.description} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={OGImage} />
          <meta property="og:locale" content="en_US" />
          <meta property="og:url" content={content.site.meta.url} />
          <meta property="og:site_name" content={content.site.meta.siteName} />
          <meta name="twitter:url" content={content.site.meta.url} />
          <meta name="twitter:title" content={content.site.meta.siteName} />
          <meta name="twitter:description" content={content.site.meta.description} />
          <meta name="twitter:image" content={OGImage} />
        </Helmet>
        <RouterProvider router={router} />
      </ChakraProvider>
    </HelmetProvider>
  );
}

export default App;
