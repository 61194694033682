import '@fontsource-variable/source-code-pro';
import '@fontsource/poppins';

import {
    extendTheme,
    withDefaultColorScheme,
    withDefaultSize,
    withDefaultVariant,
} from '@chakra-ui/react';

const theme = extendTheme(
    {
        initialColorMode: 'light',
        useSystemColorMode: false,
        colors: {
            white: '#FFFCF2',
            black: '#252422',

            'darkGray': {
                '50': '#F3F2F2',
                '100': '#DDDCD9',
                '200': '#C8C5C1',
                '300': '#B2AEA9',
                '400': '#9C9790',
                '500': '#878178',
                '600': '#6C6760',
                '700': '#514D48',
                '800': '#363330',
                '900': '#1B1A18'
            },

            'gray': {
                '50': '#F4F3F0',
                '100': '#E1DDD6',
                '200': '#CEC7BB',
                '300': '#BAB1A1',
                '400': '#A79B86',
                '500': '#94856B',
                '600': '#766A56',
                '700': '#595040',
                '800': '#3B352B',
                '900': '#1E1B15'
            },
            'red': {
                '50': '#FDEEE8',
                '100': '#F9CEBE',
                '200': '#F5AF94',
                '300': '#F18F6A',
                '400': '#ED7040',
                '500': '#E95016',
                '600': '#BB4011',
                '700': '#8C300D',
                '800': '#5D2009',
                '900': '#2F1004'
            },
        },
        fonts: {
            heading: `'Poppins', sans-serif`,
            body: `'Poppins', sans-serif`,
            mono: `'Source Code Pro', monospace`,
        },
        styles: {
            global: {
                body: {
                    bg: 'white',
                    color: 'gray.600',
                },
                a: {
                    _hover: {
                        color: 'red.500',
                        transition: 'all 150ms ease-in-out',
                        cursor: 'pointer',
                        textDecoration: 'none',
                    }
                },
                ul: {
                    paddingInlineStart: '14px',
                    listStyleType: 'circle',
                },
                li: {
                    marginBottom: 1.5,
                },
                canvas: {
                    margin: 'auto',
                    touchAction: 'none',
                }
            },
        },
    },
    withDefaultColorScheme({ colorScheme: 'red' }),
    withDefaultSize({
        size: 'lg',
        components: ['Heading'],
    }),
    withDefaultVariant({
        variant: 'outline',
        components: ['Button', 'Input'],
    }),
);

export default theme;