import React from 'react';
import { useColorModeValue } from '@chakra-ui/react';

const UnivisionLogo = () => {
    const color = useColorModeValue("black", "white");

    return (
        <svg viewBox="0 0 192.756 192.756">
            <g fillRule="evenodd" clipRule="evenodd">
                <path fill={color} d="M62.805 80.957v26.369c0 4.047-3.176 5.223-6.048 5.223-2.828 0-6.005-1.176-6.005-5.223V80.957h4.612v25.979c0 1.566.479 1.914 1.436 1.914.914 0 1.392-.348 1.392-1.914V80.957h4.613zm12.242 0 1.306 8.92a158.11 158.11 0 0 1 2.175 17.537h.174v-1.654c.043-3.002-.13-6.049-.13-9.051V80.957h3.786v30.982h-6.832L73.35 96.448c-.74-3.83-1.001-7.311-1.393-10.966h-.174c0 1.349.086 2.698.13 4.003v22.454h-3.786V80.957h6.92zm12.727 30.982V80.957h4.613v30.982h-4.613zm13.699-30.982 1.436 12.924c.348 3.045.695 9.094 1.045 12.184h.086c.262-3.742.609-7.441.914-11.139l1.393-13.968h4.613l-3.656 30.982h-6.961L96.86 80.957h4.613zm14.318 30.982V80.957h4.611v30.982h-4.611zm14.447-5.003c0 1.566.48 1.914 1.438 1.914.914 0 1.393-.348 1.393-1.914v-3.264c0-4.352-7.441-8.616-7.441-13.664V85.57c0-4.047 3.176-5.222 6.004-5.222 2.873 0 6.049 1.174 6.049 5.222v6.527h-4.611v-6.136c0-1.567-.479-1.915-1.393-1.915-.957 0-1.438.348-1.438 1.915v3.829c0 2.219 7.441 8.92 7.441 12.053v5.482c0 4.047-3.176 5.223-6.049 5.223-2.828 0-6.004-1.176-6.004-5.223v-7.137h4.611v6.748zm12.647 5.003V80.957h4.613v30.982h-4.613zm14.373-5.003c0 1.566.479 1.914 1.438 1.914.914 0 1.393-.348 1.393-1.914V85.961c0-1.567-.479-1.915-1.393-1.915-.959 0-1.438.348-1.438 1.915v20.975zm-4.612.39V85.57c0-4.047 3.176-5.222 6.004-5.222 2.873 0 6.049 1.174 6.049 5.222v21.756c0 4.047-3.176 5.223-6.049 5.223-2.828 0-6.004-1.176-6.004-5.223zm24.295-26.369 1.307 8.92a158.347 158.347 0 0 1 2.174 17.537h.176v-1.654c.043-3.002-.131-6.049-.131-9.051V80.957h3.785v30.982h-6.832l-2.176-15.491c-.74-3.83-1-7.311-1.393-10.966h-.174c0 1.349.088 2.698.131 4.003v22.454h-3.785V80.957h6.918zM8.559 97.369c.417 8.236 7.02 14.838 15.255 15.256V97.373l-15.255-.004zm16.909 15.256c8.234-.418 14.837-7.02 15.254-15.256H25.47l-.002 15.256zm15.306-16.912V80.132H25.47v15.581h15.304zm-17.016.002c-.417-8.234-7.02-14.837-15.254-15.254v15.252l15.254.002z" />
            </g>
        </svg>
    );
}

export default UnivisionLogo;
