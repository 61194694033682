import React from 'react';
import { Heart, Copyright } from '@phosphor-icons/react';

import { Box, Flex, Text } from '@chakra-ui/react';

import { Section } from './Section';

import content from '../content';

export const Footer = () => {
    const { siteFooter } = content;

    return (
        <Section>
            <Box
                textAlign="center"
                pt={{ base: 28, md: 48 }}
                pb={2}
                opacity={0.65}
            >
                <Text
                    fontWeight={300}
                    fontSize="10px"
                    maxW="540px"
                    margin="0 auto"
                    fontFamily="mono"
                >
                    {siteFooter}
                </Text>
            </Box>
            <Box pb={16}>
                <Flex
                    fontWeight={300}
                    fontSize="10px"
                    maxW={{ base: '100%', md: '600px' }}
                    margin="0 auto"
                    fontFamily="mono"
                    alignItems="center"
                    justifyContent="center"
                    gap={1}
                >
                    Built with <span className="red"><Heart weight="duotone" /></span> in Georgetown, Ohio USA · <Copyright weight="duotone" /> Samantha Black {new Date().getFullYear()}
                </Flex>
            </Box>
        </Section>
    )
}