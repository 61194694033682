import React from 'react';

import { motion } from 'framer-motion';

export const Section = ({ meta, children }) => {
    return (
        <motion.section
            id={meta ? meta.key : undefined}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{
                duration: 0.8,
            }}
        >
            {children}
        </motion.section>
    )
}